import { apiInstance, apiInstance2, simpleApiInstance } from 'utils/request'
import { javaGetCookieInstance } from 'utils/requestJAVA'
import { message } from 'antd'
import { BO_API_URL, JAVA_API_URL, MAIN_API_URL } from 'const/env'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getAdminData = (id) =>
  apiInstance
    .get(`${BO_API_URL}/${RELATIONSHIPS_NAMES.admins}/${id}`)
    .catch((e) => {
      console.error(e)
      return {
        error: {
          status: e.response.status,
          error: e.response.data.errors[0].detail,
        },
      }
    })

export const login = (data) => {
  return simpleApiInstance
    .post(`${BO_API_URL}/sessions`, data)
    .catch((error) => {
      parseError(error, 'Authentication has failed')

      throw error
    })
}

export const getCookie = ({ username, password }) =>
  javaGetCookieInstance
    .get(`${JAVA_API_URL}/auth/v1/token`, { auth: { username, password } })
    .then((response) => {
      const accessToken = response.data['access-token']
      localStorage.setItem('cookie', accessToken)

      return accessToken
    })
    .catch((error) => {
      console.error(error)
    })

export const logout = () =>
  apiInstance.delete(`${BO_API_URL}/sessions`).catch(console.error)

export const updatePaymentSettings = ({ id, data }) =>
  apiInstance
    .patch(`${BO_API_URL}/${RELATIONSHIPS_NAMES.admins}/${id}`, data)
    .catch((error) => {
      message.error("Settings haven't been saved!", 3)
      console.error(error)
    })

export const deleteAdmin = (id) =>
  apiInstance
    .delete(
      `/${RELATIONSHIPS_NAMES.lead}/${ENTITY_NAMES.backofficeUsers}/${id}`
    )
    .then(() => ({ data: 'OK' }))
    .catch(console.error)

export const getAllAdmins = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.lead}/${ENTITY_NAMES.backofficeUsers}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || `-${FIELD_NAMES.id}`,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get admins')
    })

export const updateAdmin = ({ id, data }) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.lead}/${ENTITY_NAMES.backofficeUsers}/${id}`,
      {
        data,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "User hasn't been updated")
    })

export const createAdmin = (data) =>
  apiInstance
    .post(`/${RELATIONSHIPS_NAMES.lead}/${ENTITY_NAMES.backofficeUsers}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "User hasn't been created")
    })

export const resetPassword = (data) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.lead}/${ENTITY_NAMES.backofficeUsers}/${data.id}`,
      {
        data,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Users' password wasn't updated")
    })

export const sendResetPasswordEmail = (email) =>
  apiInstance2
    .post(
      `${MAIN_API_URL}/${RELATIONSHIPS_NAMES.user}/${RELATIONSHIPS_NAMES.resetPassword}`,
      {
        email,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Password wasn't reset")
    })

export const changePassword = (data) =>
  apiInstance
    .post(
      `${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${RELATIONSHIPS_NAMES.resetPassword}`,
      {
        ...data.values,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Password wasn't changed")
    })

export const verifyMfa = ({ email, password, mfi, token }) => {
  return simpleApiInstance
    .post(`${BO_API_URL}/verify_mfa`, {
      email,
      password,
      mfi,
      token
    })
    .catch((error) => {
      parseError(error, 'MFA verification failed')
      throw error
    })
}
