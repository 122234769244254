import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Spin,
  Typography,
} from 'antd'
import { ButtonWrapper, StyledLayout } from './Login.styles'
import useAction from 'utils/useAction'
import { actions as adminActions } from 'domains/admin'
import { selectAdminLoginState } from 'domains/admin/selectors'

const layout = {
  labelCol: {
    span: 6,
    xxl: 8,
  },
  wrapperCol: {
    span: 12,
    xxl: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 12,
    xxl: {
      offset: 8,
      span: 8,
    },
  },
}

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

const Login = () => {
  const loginFunc = useAction(adminActions.adminLogin)
  const verifyMfaFunc = useAction(adminActions.verifyMfa)
  const sendResetPasswordEmail = useAction(adminActions.sendResetPasswordEmail)
  const clearMfaStateFunc = useAction(adminActions.clearMfaState)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isPasswordHidden, setIsPasswordHidden] = useState(false)

  const { mfa_required, mfi, credentials } = useSelector(selectAdminLoginState)

  const isEmailValid = (email) => {
    return !!EMAIL_REGEX.test(email)
  }

  const submitHandler = async (values) => {
    setLoading(true)

    if (isPasswordHidden) {
      if (isEmailValid(credentials.email)) {
        sendResetPasswordEmail(credentials.email)
      } else {
        message.error('Enter a valid email')
      }
    } else if (mfa_required) {
      verifyMfaFunc({
        ...credentials,
        mfi,
        token: values.mfaCode
      })
    } else {
      loginFunc(values)
    }

    setLoading(false)
  }

  const onSubmitFailed = () => {
    message.error('Login failed!', 2)
  }

  const emailChangeHandler = (e) => {
    form.setFieldValue('email', e.target.value)
  }

  const onForgotPasswordClick = () => {
    setIsPasswordHidden(!isPasswordHidden)
  }

  return (
    <StyledLayout>
      <Layout.Content>
        <Row justify="center" align="middle">
          <Col span={12}>
            <Spin spinning={loading} size="large">
              <Form
                {...layout}
                form={form}
                name="login-form"
                onFinish={submitHandler}
                onFinishFailed={onSubmitFailed}
              >
                {isPasswordHidden && (
                  <Typography.Text>
                    Enter your email to reset password
                  </Typography.Text>
                )}

                {mfa_required ? (
                  <>
                    <Typography.Text>
                      Enter the verification code sent to your phone
                    </Typography.Text>
                    <Form.Item
                      label="Verification Code"
                      name="mfaCode"
                      rules={[
                        {
                          required: true,
                          message: 'Please input the verification code!',
                        },
                      ]}
                    >
                      <Input maxLength={6} />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                        Verify
                      </Button>
                      <Button onClick={clearMfaStateFunc}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      label="Email"
                      name="email"
                      onChange={emailChangeHandler}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    {!isPasswordHidden && (
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    )}

                    <Form.Item {...tailLayout}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>

                    <ButtonWrapper>
                      {!isPasswordHidden ? (
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          onClick={onForgotPasswordClick}
                        >
                          Forgot Password?
                        </Button>
                      ) : (
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          onClick={onForgotPasswordClick}
                        >
                          Login
                        </Button>
                      )}
                    </ButtonWrapper>
                  </>
                )}
              </Form>
            </Spin>
          </Col>
        </Row>
      </Layout.Content>
    </StyledLayout>
  )
}

export default Login
