import { createSelector } from '@reduxjs/toolkit'
import { FIELD_NAMES } from 'const/forms'
import ROLES from 'const/roles'
import { HEADER_LINKS } from 'const/routes'
import {
  areFiltersAndSorterEmpty,
  getAvailableHeaderLinks,
} from 'helpers/filters'
import { formatAdmins } from './formatters'
import memoize from 'lodash/memoize'
import omitBy from 'lodash/omitBy'
import { transactionsColumns } from '../../pages/CustomerService/columns'

export const adminState = ({ admin }) => admin

export const adminId = createSelector(adminState, ({ id }) => id)

export const token = createSelector(adminState, ({ token }) => token)

export const adminData = createSelector(adminState, ({ data }) => data)

export const adminRole = createSelector(
  adminData,
  (data) => data.attributes?.[FIELD_NAMES.role]
)

export const newAdminRoles = createSelector(
  adminData,
  (data) => data.attributes?.[FIELD_NAMES.authorizedRoles] || []
)

export const getAvailableLinks = createSelector(newAdminRoles, (roles) =>
  getAvailableHeaderLinks(HEADER_LINKS, roles)
)

export const isAdmin = createSelector(adminRole, (role) => role === ROLES.ADMIN)

export const isModerator = createSelector(
  adminRole,
  (role) => role === ROLES.MODERATOR
)

export const isMember = createSelector(
  adminRole,
  (role) => role === ROLES.MEMBER
)

export const isLogged = createSelector(token, (token) => !!token)

export const paymentColumns = createSelector(adminData, (data) =>
  omitBy(
    data.attributes[FIELD_NAMES.displayPreference],
    (value, key) => !transactionsColumns[key]
  )
)

export const adminsState = createSelector(adminState, ({ admins }) => admins)

export const getAllAdmins = createSelector(adminsState, ({ data }) =>
  formatAdmins(data)
)

export const getAdminById = createSelector(getAllAdmins, (admins) =>
  memoize((id) => (id ? admins[id] : null))
)

export const getSearchString = createSelector(
  adminsState,
  ({ search }) => search
)

export const getPage = createSelector(adminsState, ({ page }) => page)

export const getFilters = createSelector(adminsState, ({ filters }) => filters)

export const getSorter = createSelector(adminsState, ({ sorter }) => sorter)

export const getPageCount = createSelector(
  adminsState,
  ({ pageCount }) => pageCount
)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getIsLoaded = createSelector(
  adminsState,
  ({ isLoaded }) => isLoaded
)

export const getSortedIds = createSelector(
  adminsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedAdmins = createSelector(
  [getAllAdmins, getSortedIds],
  (admins, ids) => ids.map((id) => admins[id])
)

export const getIsSavingActive = createSelector(
  adminsState,
  ({ isSavingActive }) => isSavingActive
)


export const selectAdminLoginState = createSelector(
  adminState,
  (admin) => ({
    mfa_required: admin.mfa_required || false,
    mfi: admin.mfi || null,
    credentials: admin.credentials || {},
  })
)
