export const MAX_BODY_TEXT_LENGTH = 60
export const MAX_HEADER_TEXT_LENGTH = 20
export const DEFAULT_MEDIA_TYPE = 'none'

// Examples of allowed links:
// https://forme.science/collections/women
// http://www.example.com
// https://sub.example.com/path
// example.com
// my-website.co.uk
// complex-domain.app.io/path/to/resource
// site.com:8080/api
// example.com/search?query=test&page=1
// example.com/article#comments
// sub.domain.with-hyphens.com/path_with_underscore
export const LINK_REGEXP =
  // eslint-disable-next-line max-len
  /^(https?:\/\/)?(www\.)?([a-z0-9]+([-\.][a-z0-9]+)*\.)+[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const APP_VERSION_REGEXP = /^(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)?$/
